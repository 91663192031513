import React from 'react';
import {Row, Col} from 'react-bootstrap';

function CurrentChickens() {
    return (
       <Row>
            <Col sm={4}>
                Search Bar
            </Col> 
            <Col sm={8}>
                Cards of Chickens
            </Col>
       </Row>
    )
}

export default CurrentChickens
