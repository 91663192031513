import React from 'react'
import { Routes, Route } from 'react-router-dom';
import App from './App';
import CurrentChickens from './Pages/CurrentChickens';
import Home from './Pages/Home';

function Router() {
    return (
        <Routes>
            <Route path="/" element={<App />} >
                <Route path="/" element={<Home />} />
                <Route path="/current-chickens" element={<CurrentChickens />} />
            </Route>
        </Routes>
    )
}

export default Router
