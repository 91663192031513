import React from 'react'
import {
  Form,
} from "react-bootstrap";

function ChickForm({chicks, updateHen}) {
    return (
       <Form className="form">
          {chicks.map((hen, index) => {
            return (
              <Form.Check
                key={hen.name}
                className="switch"
                type="switch"
                id="custom-switch"
                label={hen.name}
                checked={hen.laid}
                onChange={() => updateHen(hen)}
              />
            );
          })}
        </Form> 
    )
}

export default ChickForm
