import React from "react";
import "../CSS/Chicken.css";

function ChickenIcon({selectedHen}) {
  return (
    // <div className="chicken-logo">
    <>
      <div className="chicken">
        {console.log(selectedHen)}
        <div className="head">
          <div className="hair"></div>
        </div>
        <div className="beak"></div>
        <div className="body"></div>
        <div className="tail"></div>
        <div className="leg one"></div>
        <div className="leg two"></div>
        <div className={`egg-${selectedHen}`}></div>
      </div>
     {/* </div> */}
     </>
  );
}

export default ChickenIcon;
