import React from "react";
import { Container, Col, Row} from "react-bootstrap";

function History({ eggHistory, getMonth, dailyAverage }) {
  return (
    <>
      <p className="history-header">History:</p>
      <Container className="history-container">
        <Row className="history-row">
          {eggHistory?.map((history, index) => {
            let hist = history.date.split("-");
            return (
              <Col key={index} className="history-individual-column">
                <div className="border-divider">
                  <Row className="history-individual-row-date">
                    {getMonth(hist[1])}-{hist[2]}
                  </Row>
                  <Row className="history-individual-row-count">
                    {history.count}
                  </Row>
                </div>
              </Col>
            );
          })}
        </Row>
        <p className="daily-average">
          30 Day Daily Average: {Math.floor(dailyAverage)}
        </p>
      </Container>
    </>
  );
}

export default History;
