import React from 'react'
import {
  Container,
  Table,
} from "react-bootstrap";

function YTDStats({ytdStats}) {
    return (
        <Container className="year-to-date-total-egg-count">
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>YTD Stat</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {ytdStats?.map((stat, index) => {
                return (
                  <tr key={stat[0]}>
                    <td>{stat[0]}</td>
                    <td>{stat[1]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
    )
}

export default YTDStats
