import React from 'react'
import {
  Container,
  Table,
} from "react-bootstrap";

function YTDChickenHistory({ytdHistory}) {
    return (
        <Container className="year-to-date-history-container">
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th>Chicken Name</th>
                <th>YTD Count</th>
              </tr>
            </thead>
            <tbody>
              {ytdHistory?.map((chick, index) => {
                return (
                  <tr key={chick.name}>
                    <td>{chick.name}</td>
                    <td>{chick.ytdCount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
    )
}

export default YTDChickenHistory
