import React from "react";
import { useEffect, useState } from "react";
import { Modal} from "react-bootstrap";
import "../CSS/App.css";
import "../CSS/Body.css";
import ChickForm from "../Components/ChickForm";
import ChicksAge from "../Components/ChicksAge";
import History from "../Components/History";
import YTDChickenHistory from "../Components/YTDChickenHistory";
import YTDStats from "../Components/YTDStats";
import ChickenIcon from "../Components/ChickenIcon";

function Home() {
  const [chicks, updateChicks] = useState([]);
  const [eggHistory, updateEggHistory] = useState([]);
  const [ytdHistory, updateYTDHistory] = useState([]);
  const [ytdStats, updateYTDStats] = useState([]);
  const [dailyAverage, setDailyAverage] = useState(0);
  const [chickenView, updateChickenView] = useState(false);
  const [selectedHen, updateSelectedHen] = useState("");

  const newChicks = [
    {
      breed: "Blue Cuckoo Maran",
      birthDate: "2023-03-07",
      count: 4,
      image: "https://cdn.shopify.com/s/files/1/0601/9550/4282/products/93466247_785f9052-9d3b-4c39-b70f-33d0b382af4e.jpg?v=1667310319"
    },
    {
      breed: "Welsummer",
      birthDate: "2023-03-12",
      count: 6,
      image: "https://cdn.shopify.com/s/files/1/0601/9550/4282/products/welsummer-chicken-mpc_1.jpg?v=1673287546"
    },
    {
      breed: "Lavendar Amerucana",
      birthDate: "2023-03-07",
      count: 6,
      image: "https://cdn.shopify.com/s/files/1/0601/9550/4282/products/lavender-ameraucana-chicken-jfh.jpg?v=1669039859&width=1100"
    },
  ];

  // tunnel api endpoint
  let apiURL = "https://egg-counter-api.app.vtxhub.com";

  // current production url when on our network
  // let apiURL = "http://192.168.1.164:8887";

  // testing the local deploy url
  // let apiURL = "http://192.168.1.164:8888";

  useEffect(() => {
    getAllHens();
    updateStats();
  }, []);

  useEffect(() => {
    getHistory();
    getYTDHistory();
  }, [chicks]);

  useEffect(() => {
    updateStats();
  }, [ytdHistory]);

  const getYTDHistory = () => {
    fetch(`${apiURL}/api/chicks/ytd`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let ytdHistoryList = data;
        updateYTDHistory(ytdHistoryList);
      });
  };

  const getAllHens = () => {
    fetch(`${apiURL}/api/chicks/get`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let dailySummary = data;
        updateChicks(dailySummary.chickList);
      });
  };

  const updateHen = (hen) => {
    updateChickenView(true);
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };
      updateSelectedHen(hen.name.replaceAll(" ", ""));

      fetch(`${apiURL}/api/chicks/update?name=${hen.name}`, options)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let chickList = data.chickList;
          updateChicks(chickList);
          updateChickenView(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  let today = new Date();

  const countEggs = () => {
    let count = 0;
    chicks.forEach((hen) => {
      if (hen.laid) {
        count = count + 1;
      }
    });
    return count;
  };

  const getHistory = () => {
    fetch(`${apiURL}/api/chicks/history`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let returnedData = data;
        returnedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        let lastSixDays = returnedData.slice(1, 7);
        setDailyAverage(calculateDailyAverage(returnedData.slice(1, 31)));
        updateEggHistory(lastSixDays);
      });
  };

  const calculateDailyAverage = (lastThirtyDays) => {
    let days = lastThirtyDays.length;
    let total = 0;
    lastThirtyDays.forEach((day) => {
      total = total + day.count;
    });
    return total / days;
  };
  const getMonth = (month) => {
    // console.log(month);
    switch (parseInt(month)) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        break;
    }
  };

  const countYTDEggs = () => {
    let count = 0;
    ytdHistory.forEach((chick) => {
      count = count + chick.ytdCount;
    });
    return count;
  };

  const updateStats = () => {
    let stats = [];
    let eggvalue = (countYTDEggs() / 12) * 4;
    let YTDEGGS = [
      "Number of Eggs Produced",
      countYTDEggs(),
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT68EYD0fvvevTnAiZS00ZtL40ZQHMVbl6io_p-CDLl&s",
    ];
    let YTDEGGVALUE = ["Egg Value Produced", "$" + eggvalue.toFixed(2), ""];
    let BAGSOFFEED = [
      "Bags of Feed Based on Value",
      (eggvalue / 19.99).toFixed(2),
      "",
    ];
    let HAPPYMEALS = [
      "Happy Meals Based on Value",
      Math.floor(eggvalue / 4.79),
      "",
    ];
    let DisneyPlus = [
      "Months of Disney+ Based on Value",
      Math.floor(eggvalue / 7.99),
      "",
    ];

    stats.push(YTDEGGS);
    stats.push(YTDEGGVALUE);
    stats.push(BAGSOFFEED);
    stats.push(HAPPYMEALS);
    stats.push(DisneyPlus);

    updateYTDStats(stats);
  };

  const getWeeksAndDays = (birthdate) => {
    let birthDay = new Date(birthdate);
    const currentDate = new Date();

    const timeDiff = Math.abs(currentDate.getTime() - birthDay.getTime()); // get the difference between the two dates in milliseconds
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24)); // divide the difference by the number of milliseconds in a day and round up to get the number of days

    return `${Math.floor(daysDiff / 7)} weeks, ${daysDiff % 7} days`;
    // return weeksDiff;
  };

  return (
    <div className="App">
      <body className="egg-counter-body">
        <p id="site-title">
          Egg Tracker - {today.getMonth() + 1}/{today.getDate()}
        </p>
        <p>Todays Eggs: {countEggs()}</p>
        <ChickForm chicks={chicks} updateHen={updateHen} />
        <History
          eggHistory={eggHistory}
          getMonth={getMonth}
          dailyAverage={dailyAverage}
        />
        <ChicksAge newChicks={newChicks} getWeeksAndDays={getWeeksAndDays} />
        <YTDChickenHistory ytdHistory={ytdHistory} />
        <YTDStats ytdStats={ytdStats} />

        <Modal
          show={chickenView}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {/* <Modal.Header closeButton>
            <Modal.Title>Updating Chickens...</Modal.Title>
          </Modal.Header> */}

          <ChickenIcon selectedHen={selectedHen}></ChickenIcon>
        </Modal>
      </body>
    </div>
  );
}

export default Home;
