import React from "react";
import { Outlet } from "react-router-dom";
import "./CSS/App.css";
import "./CSS/Body.css";
import HeaderNavBar from "./Components/HeaderNavBar";

function App() {
  return (
    <>
      {/* <HeaderNavBar /> */}
      <Outlet />
    </>
  );
}

export default App;
