import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import "../CSS/ChickenAge.css"

function ChicksAge({ newChicks, getWeeksAndDays }) {
  return (
    <Row className="chick-age-row">
      {newChicks?.map((chick, index) => {
        return (
          <Col key={`${chick.type}-${index}`} className="chick-age-col">
            <Card className="chick-age-card" >
              <Card.Img
                variant="top"
                src={chick.image}
                // style={{width: "90%", margin: "auto", display: "block"}}
              />
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>
                  {chick.breed}
                </Card.Title>
                <Card.Text>{getWeeksAndDays(chick.birthDate)}</Card.Text>
                {/* <Button variant="primary">Go somewhere</Button> */}
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default ChicksAge;
